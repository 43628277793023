/** @format */

const Properties = {
  colors: {
    secondaryTint1: 'secondaryTint1',
    secondaryTint2: 'secondaryTint2',
    white: 'white',
    smoke: 'smoke'
  },
  display: {
    default: 'default',
    none: 'none',
    clean: 'clean',
    hidden: 'hidden'
  },
  flows: {
    trialFlow: 'trialFlow',
    campaignFlow: 'campaignFlow',
    giftCardFlow: 'giftCardFlow',
    reActivationFlow: 'reActivationFlow',
    changePaymentFlow: 'changePaymentFlow'
  },
  subFlows: {
    redeem: 'redeem',
    purchase: 'purchase'
  },
  bot: {
    name: 'Prerender'
  }
};

export default Properties;
