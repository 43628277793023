import Cookies from "js-cookie";
import Properties from "./properties";
import useTranslation from "next-translate/useTranslation";
import { navFooterIdSE_NL_NO, navFooterIdFI, navFooterIdDE_AT_CH, navFooterIdDK } from "./listofid";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

export const changePriceDotIntoCommas = (price, lang) => {
    if(price) {
      return price.toString().replace('.',',');
    }
};

export const getMinSubscriptionPrice = (subscriptions,language) => {
    let price = 0;
    const priceArray = subscriptions
      ? subscriptions.map(i => (typeof i.price === "number" ? i.price : Infinity))
      : [0];
    price = Math.min(...priceArray);
    return changePriceDotIntoCommas(price,language)
};

export const getTrialDay = data => {
    if (data && data.length > 0) {
      const trialDay = data[0];
      return trialDay.trial_days;
    }
};

export const getCtaUrl = (t, subscriptionInfo, userDetails, heroStartBlock=false) => {
    let url = t("lang_route:trialFlow.subscriptionMessage_2");
    let trackCTAClickWithGTM = true;
    let continuebutton = false;
    const isPaymentRequired = subscriptionInfo.subscriptionInfo && subscriptionInfo.subscriptionInfo.data && subscriptionInfo.subscriptionInfo.data.paymentrequired;
    const isLoggedIn = userDetails.isLoggedIn;
    const cookiesFlow = Cookies.get("flow");
    const { flows } = Properties;
    if (!isLoggedIn && isPaymentRequired) {
        trackCTAClickWithGTM = false;
        url =
        cookiesFlow === flows.campaignFlow
          ? t("lang_route:campaignFlow.checkout")
          : t("lang_route:trialFlow.checkoutOptions_2");
        if (heroStartBlock) {
            url = t("lang_route:trialFlow.checkoutOptions_2");
        }
        continuebutton = true;
    }
    return {
        ctaUrl: url,
        trackCTAClickWithGTM,
        continuebutton
      };
};

export const getEventId = () => {
    let current_timestamp = Math.floor(new Date(new Date().toUTCString()) / 1000);
    let event_id =
    "id_" +
    current_timestamp +
    "_" +
    Math.random()
      .toString(16)
      .slice(2);
    return event_id;
};

export const isServer = !(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
);


export const t = (key, values={}, options={}) => {
    var { t } = useTranslation();
    var output = t(key, values, options);
    if (typeof output === 'string') {
        var re = /\$t\(([^\)]+)\)/g;
        if (output.match(re) !== null)
        {
            var condition = output.match(re) !== null;
            while (condition) {
                output = output.replace(re, function(match, p1) {
                        return t(p1);
                });
                condition = output.match(re) !== null;
            }
        }
        return output;
    } else {
        return output;
    }
    
};

export const getOrganizationSchema = (url, locale) => {
  const defaultImage = `${process.env[`ORIGIN_${locale.replace("-", "_")}`]}/cdn/images/shared/nextory-share-pink.jpg`;
      
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Nextory",
      url: url,
      logo: `${defaultImage}`,
      sameAs: [
        "https://www.facebook.com/nextory/",
        "https://www.linkedin.com/company/nextory",
        "https://www.youtube.com/channel/UC2IqgcVr3n-ekah1S1w7hHw/",
        "https://www.pinterest.se/nextorycom/"
      ],
      address: {
        "@type": "PostalAddress",
        addressCountry: "Sweden",
        addressLocality: "Stockholm",
        streetAddress: "Dalagatan 7",
        postalCode: "11123"
      }
    };
  };


export const setIdToFooter = (locale) => {
    if(locale === "fi-FI"){
        return navFooterIdFI
      } else if(locale === "de-DE" || locale === "de-AT" || locale === "de-AT"){
        return navFooterIdDE_AT_CH
      } else if (locale === "da-DK"){
        return navFooterIdDK
      } else {
        return navFooterIdSE_NL_NO
      }
}

export const getEbook = book => {
  const { meta } = book;
  const tempBookTypes = getBookTypes(book);
  const ebook = find(tempBookTypes, { type: "E_BOOK" }) || {};
  if (ebook) {
    ebook.language = meta.language;
  }

  return ebook;
};

export const getAudiobook = book => {
  const { meta } = book;
  const tempBookTypes = getBookTypes(book);
  const audiobook = find(tempBookTypes, { type: "AUDIO_BOOK" }) || {};
  if (audiobook) {
    audiobook.language = meta.language;
  }
  return audiobook;
};

export const bookCoverURL = (id, size = 340) => {
  // Check if domain should be production or staging
  let domain = "www.nextory.se";
  let coverURL = id;
  // Check if parameter id is an image URL and then extract it as proper id

  if (coverURL && !coverURL.includes("/")) {
    coverURL = `https://${domain}/coverimg/${size}/${id}`;
  } else {
    coverURL = coverURL.split("/");
    coverURL.splice(-2, 1, size);
    coverURL = coverURL.join("/");
  }
  return coverURL;
};


export const isEbook = book => {
  const tempBookTypes = getBookTypes(book);
  return !isEmpty(filter(tempBookTypes, { type: "E_BOOK" }));
};

export const isAudiobook = book => {
  const tempBookTypes = getBookTypes(book);
  return !isEmpty(filter(tempBookTypes, { type: "AUDIO_BOOK" }));
};

export const getAuthorName = book => {
  if (book.authors && book.authors.length > 0) {
    const length = book.authors.length;
    const authorName = book.authors.map(item => item.name);
    if (length > 2) {
      return authorName.slice(0, 3).join(", ");
    } else if (length === 2) {
      return `${authorName[0]} & ${authorName[1]}`;
    } else {
      return authorName[0];
    }
  }
};

export const imagePath = "/cdn/images/";
export const NextoryPinkLogo = `${imagePath}/shared/nextory-share-pink.jpg`;

export const imageHelper = (src, external) => {
  if (external) {
    return src;
  }

  return `${imagePath}${src}`;
};

const getBookTypes = book => {
  return book && book.booktypes ? book.booktypes : [];
};

export const authTokenExpiredCheck = tokens => {
  if (tokens.authkey !== "") {
    const currentTime = new Date().getTime();
    const gmtDate = new Date(tokens.authExpired);
    const localDate = gmtDate.toLocaleString();
    const refreshThreshold = new Date(localDate).getTime();
    if (currentTime > refreshThreshold) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const getPersonAuthorList = (personList, origin) => {
  let personObject = [];
  const categoriesAllRoute =
    t("lang_route:books.category") +
    t("lang_route:books.categoryall").replace(/\/$/, "");
  personList.forEach((person) => {
    personObject.push({
      "@type": "Person",
      name: person.name,
      url:
        origin +
        categoriesAllRoute +
        t("lang_route:books.author", {
          authorslug: person.slug,
        }) +
        "?ref=author",
    });
  });
  return personObject;
};

const getPersonList = (personList) => {
  let personObject = [];
  personList.forEach((person) => {
    personObject.push({
      '@type': 'Person',
      name: person
    });
  });
  return personObject;
};

export const getBookInfoSchema = (book, bookType, origin) => {
  let tBook;

  if (bookType === "E_BOOK") {
    if (isEbook(book)) {
      tBook = getEbook(book);
      tBook.typeFormat = "Book";
      tBook.bookFormat = "EBook";
      tBook.datePublished = tBook.published;
    }
  }

  if (bookType === "AUDIO_BOOK") {
    if (isAudiobook(book)) {
      tBook = getAudiobook(book);
      tBook.typeFormat = "Audiobook";
      tBook.bookFormat = "AudiobookFormat";
      tBook.datePublished = tBook.published;
    }
  }

  const content = {
    "@context": "https://schema.org",
    "@type": tBook.typeFormat,
    bookFormat: tBook.bookFormat,
    name: book.meta.title,
    isbn: tBook.isbn,
    description: book.meta.description.replace(/<\/?[^>]+(>|$)/g, ""),
    image: bookCoverURL(book.meta.image, 340),
    author: book.authors
      ? getPersonAuthorList(
          book.authors.map((item) => item),
          origin
        )
      : {},
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: tBook.rating ? tBook.rating.score : 0,
      ratingCount: tBook.rating ? tBook.rating.count : 0,
    },
    offers: {
      "@type": "AggregateOffer",
      availability: "http://schema.org/InStock",
      lowPrice: "0",
      highPrice: t("lang_common:subscription_prices_numeric.family4"),
      priceCurrency: t("lang_common:currency"),
    },
    datePublished: tBook?.datePublished ? tBook.datePublished : "",
    inLanguage: tBook?.language ? getLangName[tBook.language] : "",
    publisher: {
      "@type": "Organization",
      "name": book?.booktypes[0]?.publisher? book.booktypes[0].publisher : ''
    }

  };

  if (book.narrators && isAudiobook(book)) {
    content.readBy = book.narrators
      ? getPersonList(book.narrators.map((item) => item.name))
      : {};
  }

  return content;
};

export const localeMap = {
  "sv-SE": "se",
  "fi-FI": "fi",
  "de-DE": "de",
  "da-DK": "dk",
  "de-AT": "at",
  "de-CH": "ch",
  "nl-NL": "nl",
  "nb-NO": "no",
  "es-ES": "es",
};