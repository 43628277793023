/** @format */

export const navFooterIdSE_NL_NO = [
    'cp_lnk_abt',
    '',
    '',
    'cp_lnk_career',
    '',
    'cp_lnk_help',
    'cp_lnk_sell',
    'cp_lnk_news',
    'cp_lnk_news',
    '',
    '',
    '',
    '',
    'cp_lnk_inv_rel',
    'cp_lnk_inv_rel'
  ];
  export const navFooterIdFI = [
    'cp_lnk_abt',
    '',
    '',
    'cp_lnk_cr_accnt',
    '',
    'cp_lnk_sell',
    '',
    'cp_lnk_news',
    'cp_lnk_open_job',
    '',
    '',
    '',
    '',
    '',
    'cp_lnk_inv_rel'
  ];
  export const navFooterIdDE_AT_CH = [
    'cp_lnk_abt',
    'cp_lnk_imprnt',
    'cp_lnk_our_subs',
    '',
    '',
    'cp_lnk_sell',
    '',
    'cp_lnk_news',
    '',
    'cp_lnk_career',
    'cp_lnk_cr_accnt',
    '',
    '',
    '',
    'cp_lnk_inv_rel'
  ];
  export const navFooterIdDK = [
    'cp_lnk_abt',
    '',
    '',
    'cp_lnk_career',
    '',
    'cp_lnk_sell',
    '',
    'cp_lnk_news',
    '',
    '',
    '',
    '',
    '',
    '',
    'cp_lnk_inv_rel'
  ];

  export const footerLinkId = ['cp_lnk_pp', 'cp_lnk_terms', 'cp_lnk_cks'];
  
  export const menuListTopId = [
    'hp_mn_lnk_home',
    'hp_mn_lnk_srch',
    'hp_mn_lnk_cat',
    'hp_mn_lnk_ebk',
    'hp_mn_lnk_abk',
    'hp_mn_lnk_be_ar',
    'hp_mn_lnk_hlp'
  ];
  
  export const menuListMiddleId = [
    'hp_mn_lnk_app',
    'hp_mn_lnk_red_camp',
    'hp_mn_lnk_red_gc',
    'hp_mn_lnk_buy_gc'
  ];
 
  export const homepageSubsHeaderId = {
    SILVER:'hp_sub_sil_head',
    GOLD:'hp_sub_gol_head',
    BRONZE:'hp_sub_bron_head',
    FAMILY:'hp_sub_fam_head'
  };
  export const homepageSubsDaysId = {
    SILVER:'hp_sub_sil_days',
    GOLD:'hp_sub_gol_days',
    BRONZE:'hp_sub_bron_days',
    FAMILY:'hp_sub_fam_days'
  };
  export const homepageSubsPriceId = {
    SILVER:'hp_sub_sil_price',
    GOLD:'hp_sub_gol_price',
    BRONZE:'hp_sub_bron_price',
    FAMILY:'hp_sub_fam_price'
  };
  export const homepageSubsTypeId = {
    SILVER:'hp_sub_sil_type',
    GOLD:'hp_sub_gol_type',
    BRONZE:'hp_sub_bron_type',
    FAMILY:'hp_sub_fam_type'
  };
  export const homepageSubsButtonId = {
    SILVER:'hp_sub_sil_but',
    GOLD:'hp_sub_gol_but',
    BRONZE:'hp_sub_bron_but',
    FAMILY:'hp_sub_fam_but'
  };
  